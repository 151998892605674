body {
	margin: 0;
}

.calcite-theme-light {
	--calcite-ui-brand: #d42323;
}
.esri-basemap-gallery__item--selected,
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected:hover,
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected:focus {
	border-left-color: #d42323;
	background-color: #d423230f;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #000000 #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 5px;
}

*::-webkit-scrollbar-track {
	background: #ebebeb;
	border: 0px none #ffffff;
	border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
	background-color: #000000;
	border-radius: 50px;
	border: 0px none #ffffff;
}

.esri-swipe__handle {
	background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 57.4 52.8' style='enable-background:new 0 0 57.4 52.8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23DC2829;%7D .st1%7Bfill:none;stroke:%23FFFFFF;stroke-linecap:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M39,3H17.4c-1.7,0-3.3,0.9-4.2,2.4L2.3,24.1c-0.9,1.5-0.9,3.3,0,4.8l10.8,18.7c0.9,1.5,2.5,2.4,4.2,2.4h21.6 c1.7,0,3.3-0.9,4.2-2.4l10.8-18.7c0.9-1.5,0.9-3.3,0-4.8L43.1,5.4C42.3,3.8,40.7,3,39,3z'/%3E%3Cg%3E%3Cg%3E%3Cline class='st1' x1='13.4' y1='26.4' x2='19.4' y2='20.5'/%3E%3Cline class='st1' x1='19.4' y1='32.5' x2='13.5' y2='26.5'/%3E%3C/g%3E%3Cg%3E%3Cline class='st1' x1='44.2' y1='26.6' x2='38.2' y2='32.5'/%3E%3Cline class='st1' x1='38.3' y1='20.5' x2='44.2' y2='26.5'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	border: 0;
	background-repeat: no-repeat;
}

.esri-swipe__handle-icon {
	display: none;
}

.MuiPopover-paper {
	pointer-events: auto;
}

.MuiMenu-list {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

@keyframes rotate-center {
	0% {
		transform: rotate(0);
	}
	25% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.esri-ui {
	z-index: 5;
}

.esri-expand__container {
	max-height: 50%;
}

.attribution {
	--lumo-base-color: #fff;
	--lumo-tint-5pct: hsla(0, 0%, 100%, 0.3);
	--lumo-tint-10pct: hsla(0, 0%, 100%, 0.37);
	--lumo-tint-20pct: hsla(0, 0%, 100%, 0.44);
	--lumo-tint-30pct: hsla(0, 0%, 100%, 0.5);
	--lumo-tint-40pct: hsla(0, 0%, 100%, 0.57);
	--lumo-tint-50pct: hsla(0, 0%, 100%, 0.64);
	--lumo-tint-60pct: hsla(0, 0%, 100%, 0.7);
	--lumo-tint-70pct: hsla(0, 0%, 100%, 0.77);
	--lumo-tint-80pct: hsla(0, 0%, 100%, 0.84);
	--lumo-tint-90pct: hsla(0, 0%, 100%, 0.9);
	--lumo-tint: #fff;
	--lumo-shade-5pct: hsla(214, 61%, 25%, 0.05);
	--lumo-shade-10pct: hsla(214, 57%, 24%, 0.1);
	--lumo-shade-20pct: hsla(214, 53%, 23%, 0.16);
	--lumo-shade-30pct: hsla(214, 50%, 22%, 0.26);
	--lumo-shade-40pct: hsla(214, 47%, 21%, 0.38);
	--lumo-shade-50pct: hsla(214, 45%, 20%, 0.5);
	--lumo-shade-60pct: hsla(214, 43%, 19%, 0.61);
	--lumo-shade-70pct: hsla(214, 42%, 18%, 0.72);
	--lumo-shade-80pct: hsla(214, 41%, 17%, 0.83);
	--lumo-shade-90pct: hsla(214, 40%, 16%, 0.94);
	--lumo-shade: hsl(214, 35%, 15%);
	--lumo-contrast-5pct: var(--lumo-shade-5pct);
	--lumo-contrast-10pct: var(--lumo-shade-10pct);
	--lumo-contrast-20pct: var(--lumo-shade-20pct);
	--lumo-contrast-30pct: var(--lumo-shade-30pct);
	--lumo-contrast-40pct: var(--lumo-shade-40pct);
	--lumo-contrast-50pct: var(--lumo-shade-50pct);
	--lumo-contrast-60pct: var(--lumo-shade-60pct);
	--lumo-contrast-70pct: var(--lumo-shade-70pct);
	--lumo-contrast-80pct: var(--lumo-shade-80pct);
	--lumo-contrast-90pct: var(--lumo-shade-90pct);
	--lumo-contrast: var(--lumo-shade);
	--lumo-header-text-color: var(--lumo-contrast);
	--lumo-body-text-color: var(--lumo-contrast-90pct);
	--lumo-secondary-text-color: var(--lumo-contrast-70pct);
	--lumo-tertiary-text-color: var(--lumo-contrast-50pct);
	--lumo-disabled-text-color: var(--lumo-contrast-30pct);
	--lumo-primary-color: hsl(214, 90%, 52%);
	--lumo-primary-color-50pct: hsla(214, 90%, 52%, 0.5);
	--lumo-primary-color-10pct: hsla(214, 90%, 52%, 0.1);
	--lumo-primary-text-color: var(--lumo-primary-color);
	--lumo-primary-contrast-color: #fff;
	--lumo-error-color: hsl(3, 100%, 61%);
	--lumo-error-color-50pct: hsla(3, 100%, 60%, 0.5);
	--lumo-error-color-10pct: hsla(3, 100%, 60%, 0.1);
	--lumo-error-text-color: hsl(3, 92%, 53%);
	--lumo-error-contrast-color: #fff;
	--lumo-success-color: hsl(145, 80%, 42%);
	--lumo-success-color-50pct: hsla(145, 76%, 44%, 0.55);
	--lumo-success-color-10pct: hsla(145, 76%, 44%, 0.12);
	--lumo-success-text-color: hsl(145, 100%, 32%);
	--lumo-success-contrast-color: #fff;
	--lumo-icons-align-center: "\ea01";
	--lumo-icons-align-left: "\ea02";
	--lumo-icons-align-right: "\ea03";
	--lumo-icons-angle-down: "\ea04";
	--lumo-icons-angle-left: "\ea05";
	--lumo-icons-angle-right: "\ea06";
	--lumo-icons-angle-up: "\ea07";
	--lumo-icons-arrow-down: "\ea08";
	--lumo-icons-arrow-left: "\ea09";
	--lumo-icons-arrow-right: "\ea0a";
	--lumo-icons-arrow-up: "\ea0b";
	--lumo-icons-bar-chart: "\ea0c";
	--lumo-icons-bell: "\ea0d";
	--lumo-icons-calendar: "\ea0e";
	--lumo-icons-checkmark: "\ea0f";
	--lumo-icons-chevron-down: "\ea10";
	--lumo-icons-chevron-left: "\ea11";
	--lumo-icons-chevron-right: "\ea12";
	--lumo-icons-chevron-up: "\ea13";
	--lumo-icons-clock: "\ea14";
	--lumo-icons-cog: "\ea15";
	--lumo-icons-cross: "\ea16";
	--lumo-icons-download: "\ea17";
	--lumo-icons-dropdown: "\ea18";
	--lumo-icons-edit: "\ea19";
	--lumo-icons-error: "\ea1a";
	--lumo-icons-eye: "\ea1b";
	--lumo-icons-eye-disabled: "\ea1c";
	--lumo-icons-menu: "\ea1d";
	--lumo-icons-minus: "\ea1e";
	--lumo-icons-ordered-list: "\ea1f";
	--lumo-icons-phone: "\ea20";
	--lumo-icons-photo: "\ea21";
	--lumo-icons-play: "\ea22";
	--lumo-icons-plus: "\ea23";
	--lumo-icons-redo: "\ea24";
	--lumo-icons-reload: "\ea25";
	--lumo-icons-search: "\ea26";
	--lumo-icons-undo: "\ea27";
	--lumo-icons-unordered-list: "\ea28";
	--lumo-icons-upload: "\ea29";
	--lumo-icons-user: "\ea2a";
	--lumo-size-xs: 1.625rem;
	--lumo-size-s: 1.875rem;
	--lumo-size-m: 2.25rem;
	--lumo-size-l: 2.75rem;
	--lumo-size-xl: 3.5rem;
	--lumo-icon-size-s: 1.25em;
	--lumo-icon-size-m: 1.5em;
	--lumo-icon-size-l: 2.25em;
	--lumo-icon-size: var(--lumo-icon-size-m);
	--lumo-space-xs: 0.25rem;
	--lumo-space-s: 0.5rem;
	--lumo-space-m: 1rem;
	--lumo-space-l: 1.5rem;
	--lumo-space-xl: 2.5rem;
	--lumo-space-wide-xs: calc(var(--lumo-space-xs) / 2) var(--lumo-space-xs);
	--lumo-space-wide-s: calc(var(--lumo-space-s) / 2) var(--lumo-space-s);
	--lumo-space-wide-m: calc(var(--lumo-space-m) / 2) var(--lumo-space-m);
	--lumo-space-wide-l: calc(var(--lumo-space-l) / 2) var(--lumo-space-l);
	--lumo-space-wide-xl: calc(var(--lumo-space-xl) / 2) var(--lumo-space-xl);
	--lumo-space-tall-xs: var(--lumo-space-xs) calc(var(--lumo-space-xs) / 2);
	--lumo-space-tall-s: var(--lumo-space-s) calc(var(--lumo-space-s) / 2);
	--lumo-space-tall-m: var(--lumo-space-m) calc(var(--lumo-space-m) / 2);
	--lumo-space-tall-l: var(--lumo-space-l) calc(var(--lumo-space-l) / 2);
	--lumo-space-tall-xl: var(--lumo-space-xl) calc(var(--lumo-space-xl) / 2);
	--lumo-border-radius-s: 0.25em;
	--lumo-border-radius-m: var(--lumo-border-radius, 0.25em);
	--lumo-border-radius-l: 0.5em;
	--lumo-border-radius: 0.25em;
	--lumo-box-shadow-xs: 0 1px 4px -1px var(--lumo-shade-50pct);
	--lumo-box-shadow-s: 0 2px 4px -1px var(--lumo-shade-20pct), 0 3px 12px -1px var(--lumo-shade-30pct);
	--lumo-box-shadow-m: 0 2px 6px -1px var(--lumo-shade-20pct), 0 8px 24px -4px var(--lumo-shade-40pct);
	--lumo-box-shadow-l: 0 3px 18px -2px var(--lumo-shade-20pct), 0 12px 48px -6px var(--lumo-shade-40pct);
	--lumo-box-shadow-xl: 0 4px 24px -3px var(--lumo-shade-20pct), 0 18px 64px -8px var(--lumo-shade-40pct);
	--lumo-clickable-cursor: default;
	--lumo-font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", Helvetica, Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--lumo-font-size-xxs: 0.75rem;
	--lumo-font-size-xs: 0.8125rem;
	--lumo-font-size-s: 0.875rem;
	--lumo-font-size-m: 1rem;
	--lumo-font-size-l: 1.125rem;
	--lumo-font-size-xl: 1.375rem;
	--lumo-font-size-xxl: 1.75rem;
	--lumo-font-size-xxxl: 2.5rem;
	--lumo-line-height-xs: 1.25;
	--lumo-line-height-s: 1.375;
	--lumo-line-height-m: 1.625;
	--calcite-animation-timing: 300ms;
	--calcite-popper-transition: 150ms ease-in-out;
	--calcite-code-family: "Consolas", "Andale Mono", "Lucida Console", "Monaco", "monospace";
	--calcite-sans-family: "Avenir Next", "Avenir", "Helvetica Neue", "sans-serif";
	--calcite-font-size--3: 0.625rem;
	--calcite-font-size--2: 0.75rem;
	--calcite-font-size--1: 0.875rem;
	--calcite-font-size-0: 1rem;
	--calcite-font-size-1: 1.125rem;
	--calcite-font-size-2: 1.25rem;
	--calcite-font-size-3: 1.625rem;
	--calcite-font-size-4: 2rem;
	--calcite-font-size-5: 2.5rem;
	--calcite-font-size-6: 3rem;
	--calcite-font-size-7: 3.5rem;
	--calcite-font-size-8: 4rem;
	--calcite-font-weight-light: 300;
	--calcite-font-weight-normal: 400;
	--calcite-font-weight-medium: 500;
	--calcite-font-weight-bold: 600;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	--calcite-border-radius: 4px;
	--calcite-ui-opacity-disabled: 0.5;
	--calcite-panel-width-multiplier: 1;
	--esri-calcite-theme-name: "light";
	-webkit-tap-highlight-color: transparent;
	--calcite-ui-brand-hover: #00619b;
	--calcite-ui-brand-press: #004874;
	--calcite-ui-background: #f8f8f8;
	--calcite-ui-foreground-1: #ffffff;
	--calcite-ui-foreground-2: #f3f3f3;
	--calcite-ui-foreground-3: #eaeaea;
	--calcite-ui-text-1: #151515;
	--calcite-ui-text-2: #4a4a4a;
	--calcite-ui-text-3: #6a6a6a;
	--calcite-ui-text-inverse: #ffffff;
	--calcite-ui-text-link: #00619b;
	--calcite-ui-border-1: #cacaca;
	--calcite-ui-border-2: #d4d4d4;
	--calcite-ui-border-3: #dfdfdf;
	--calcite-ui-border-input: #949494;
	--calcite-ui-info: #00619b;
	--calcite-ui-success: #35ac46;
	--calcite-ui-warning: #edd317;
	--calcite-ui-danger: #d83020;
	--calcite-ui-danger-hover: #a82b1e;
	--calcite-ui-danger-press: #7c1d13;
	--calcite-theme-name: "light";
	--calcite-ui-foreground-current: #c7eaff;
	--calcite-ui-inverse: #353535;
	--calcite-ui-inverse-hover: #2b2b2b;
	--calcite-ui-inverse-press: #202020;
	--calcite-alert-dismiss-progress-background: rgba(255, 255, 255, 0.8);
	--calcite-button-transparent-hover: rgba(0, 0, 0, 0.05);
	--calcite-button-transparent-press: rgba(0, 0, 0, 0.08);
	--calcite-link-blue-underline: rgba(0, 97, 155, 0.4);
	--calcite-scrim-background: rgba(255, 255, 255, 0.85);
	--calcite-ui-brand: #d42323;
	direction: ltr;
	unicode-bidi: isolate;
	box-sizing: border-box;
	color: #323232;
	font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
	line-height: 16px;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	pointer-events: auto;
	bottom: 0;
	right: 0;
	position: absolute;
	width: 100%;
}

.attribution_text {
	--lumo-base-color: #fff;
	--lumo-tint-5pct: hsla(0, 0%, 100%, 0.3);
	--lumo-tint-10pct: hsla(0, 0%, 100%, 0.37);
	--lumo-tint-20pct: hsla(0, 0%, 100%, 0.44);
	--lumo-tint-30pct: hsla(0, 0%, 100%, 0.5);
	--lumo-tint-40pct: hsla(0, 0%, 100%, 0.57);
	--lumo-tint-50pct: hsla(0, 0%, 100%, 0.64);
	--lumo-tint-60pct: hsla(0, 0%, 100%, 0.7);
	--lumo-tint-70pct: hsla(0, 0%, 100%, 0.77);
	--lumo-tint-80pct: hsla(0, 0%, 100%, 0.84);
	--lumo-tint-90pct: hsla(0, 0%, 100%, 0.9);
	--lumo-tint: #fff;
	--lumo-shade-5pct: hsla(214, 61%, 25%, 0.05);
	--lumo-shade-10pct: hsla(214, 57%, 24%, 0.1);
	--lumo-shade-20pct: hsla(214, 53%, 23%, 0.16);
	--lumo-shade-30pct: hsla(214, 50%, 22%, 0.26);
	--lumo-shade-40pct: hsla(214, 47%, 21%, 0.38);
	--lumo-shade-50pct: hsla(214, 45%, 20%, 0.5);
	--lumo-shade-60pct: hsla(214, 43%, 19%, 0.61);
	--lumo-shade-70pct: hsla(214, 42%, 18%, 0.72);
	--lumo-shade-80pct: hsla(214, 41%, 17%, 0.83);
	--lumo-shade-90pct: hsla(214, 40%, 16%, 0.94);
	--lumo-shade: hsl(214, 35%, 15%);
	--lumo-contrast-5pct: var(--lumo-shade-5pct);
	--lumo-contrast-10pct: var(--lumo-shade-10pct);
	--lumo-contrast-20pct: var(--lumo-shade-20pct);
	--lumo-contrast-30pct: var(--lumo-shade-30pct);
	--lumo-contrast-40pct: var(--lumo-shade-40pct);
	--lumo-contrast-50pct: var(--lumo-shade-50pct);
	--lumo-contrast-60pct: var(--lumo-shade-60pct);
	--lumo-contrast-70pct: var(--lumo-shade-70pct);
	--lumo-contrast-80pct: var(--lumo-shade-80pct);
	--lumo-contrast-90pct: var(--lumo-shade-90pct);
	--lumo-contrast: var(--lumo-shade);
	--lumo-header-text-color: var(--lumo-contrast);
	--lumo-body-text-color: var(--lumo-contrast-90pct);
	--lumo-secondary-text-color: var(--lumo-contrast-70pct);
	--lumo-tertiary-text-color: var(--lumo-contrast-50pct);
	--lumo-disabled-text-color: var(--lumo-contrast-30pct);
	--lumo-primary-color: hsl(214, 90%, 52%);
	--lumo-primary-color-50pct: hsla(214, 90%, 52%, 0.5);
	--lumo-primary-color-10pct: hsla(214, 90%, 52%, 0.1);
	--lumo-primary-text-color: var(--lumo-primary-color);
	--lumo-primary-contrast-color: #fff;
	--lumo-error-color: hsl(3, 100%, 61%);
	--lumo-error-color-50pct: hsla(3, 100%, 60%, 0.5);
	--lumo-error-color-10pct: hsla(3, 100%, 60%, 0.1);
	--lumo-error-text-color: hsl(3, 92%, 53%);
	--lumo-error-contrast-color: #fff;
	--lumo-success-color: hsl(145, 80%, 42%);
	--lumo-success-color-50pct: hsla(145, 76%, 44%, 0.55);
	--lumo-success-color-10pct: hsla(145, 76%, 44%, 0.12);
	--lumo-success-text-color: hsl(145, 100%, 32%);
	--lumo-success-contrast-color: #fff;
	--lumo-icons-align-center: "\ea01";
	--lumo-icons-align-left: "\ea02";
	--lumo-icons-align-right: "\ea03";
	--lumo-icons-angle-down: "\ea04";
	--lumo-icons-angle-left: "\ea05";
	--lumo-icons-angle-right: "\ea06";
	--lumo-icons-angle-up: "\ea07";
	--lumo-icons-arrow-down: "\ea08";
	--lumo-icons-arrow-left: "\ea09";
	--lumo-icons-arrow-right: "\ea0a";
	--lumo-icons-arrow-up: "\ea0b";
	--lumo-icons-bar-chart: "\ea0c";
	--lumo-icons-bell: "\ea0d";
	--lumo-icons-calendar: "\ea0e";
	--lumo-icons-checkmark: "\ea0f";
	--lumo-icons-chevron-down: "\ea10";
	--lumo-icons-chevron-left: "\ea11";
	--lumo-icons-chevron-right: "\ea12";
	--lumo-icons-chevron-up: "\ea13";
	--lumo-icons-clock: "\ea14";
	--lumo-icons-cog: "\ea15";
	--lumo-icons-cross: "\ea16";
	--lumo-icons-download: "\ea17";
	--lumo-icons-dropdown: "\ea18";
	--lumo-icons-edit: "\ea19";
	--lumo-icons-error: "\ea1a";
	--lumo-icons-eye: "\ea1b";
	--lumo-icons-eye-disabled: "\ea1c";
	--lumo-icons-menu: "\ea1d";
	--lumo-icons-minus: "\ea1e";
	--lumo-icons-ordered-list: "\ea1f";
	--lumo-icons-phone: "\ea20";
	--lumo-icons-photo: "\ea21";
	--lumo-icons-play: "\ea22";
	--lumo-icons-plus: "\ea23";
	--lumo-icons-redo: "\ea24";
	--lumo-icons-reload: "\ea25";
	--lumo-icons-search: "\ea26";
	--lumo-icons-undo: "\ea27";
	--lumo-icons-unordered-list: "\ea28";
	--lumo-icons-upload: "\ea29";
	--lumo-icons-user: "\ea2a";
	--lumo-size-xs: 1.625rem;
	--lumo-size-s: 1.875rem;
	--lumo-size-m: 2.25rem;
	--lumo-size-l: 2.75rem;
	--lumo-size-xl: 3.5rem;
	--lumo-icon-size-s: 1.25em;
	--lumo-icon-size-m: 1.5em;
	--lumo-icon-size-l: 2.25em;
	--lumo-icon-size: var(--lumo-icon-size-m);
	--lumo-space-xs: 0.25rem;
	--lumo-space-s: 0.5rem;
	--lumo-space-m: 1rem;
	--lumo-space-l: 1.5rem;
	--lumo-space-xl: 2.5rem;
	--lumo-space-wide-xs: calc(var(--lumo-space-xs) / 2) var(--lumo-space-xs);
	--lumo-space-wide-s: calc(var(--lumo-space-s) / 2) var(--lumo-space-s);
	--lumo-space-wide-m: calc(var(--lumo-space-m) / 2) var(--lumo-space-m);
	--lumo-space-wide-l: calc(var(--lumo-space-l) / 2) var(--lumo-space-l);
	--lumo-space-wide-xl: calc(var(--lumo-space-xl) / 2) var(--lumo-space-xl);
	--lumo-space-tall-xs: var(--lumo-space-xs) calc(var(--lumo-space-xs) / 2);
	--lumo-space-tall-s: var(--lumo-space-s) calc(var(--lumo-space-s) / 2);
	--lumo-space-tall-m: var(--lumo-space-m) calc(var(--lumo-space-m) / 2);
	--lumo-space-tall-l: var(--lumo-space-l) calc(var(--lumo-space-l) / 2);
	--lumo-space-tall-xl: var(--lumo-space-xl) calc(var(--lumo-space-xl) / 2);
	--lumo-border-radius-s: 0.25em;
	--lumo-border-radius-m: var(--lumo-border-radius, 0.25em);
	--lumo-border-radius-l: 0.5em;
	--lumo-border-radius: 0.25em;
	--lumo-box-shadow-xs: 0 1px 4px -1px var(--lumo-shade-50pct);
	--lumo-box-shadow-s: 0 2px 4px -1px var(--lumo-shade-20pct), 0 3px 12px -1px var(--lumo-shade-30pct);
	--lumo-box-shadow-m: 0 2px 6px -1px var(--lumo-shade-20pct), 0 8px 24px -4px var(--lumo-shade-40pct);
	--lumo-box-shadow-l: 0 3px 18px -2px var(--lumo-shade-20pct), 0 12px 48px -6px var(--lumo-shade-40pct);
	--lumo-box-shadow-xl: 0 4px 24px -3px var(--lumo-shade-20pct), 0 18px 64px -8px var(--lumo-shade-40pct);
	--lumo-clickable-cursor: default;
	--lumo-font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", Helvetica, Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--lumo-font-size-xxs: 0.75rem;
	--lumo-font-size-xs: 0.8125rem;
	--lumo-font-size-s: 0.875rem;
	--lumo-font-size-m: 1rem;
	--lumo-font-size-l: 1.125rem;
	--lumo-font-size-xl: 1.375rem;
	--lumo-font-size-xxl: 1.75rem;
	--lumo-font-size-xxxl: 2.5rem;
	--lumo-line-height-xs: 1.25;
	--lumo-line-height-s: 1.375;
	--lumo-line-height-m: 1.625;
	--calcite-animation-timing: 300ms;
	--calcite-popper-transition: 150ms ease-in-out;
	--calcite-code-family: "Consolas", "Andale Mono", "Lucida Console", "Monaco", "monospace";
	--calcite-sans-family: "Avenir Next", "Avenir", "Helvetica Neue", "sans-serif";
	--calcite-font-size--3: 0.625rem;
	--calcite-font-size--2: 0.75rem;
	--calcite-font-size--1: 0.875rem;
	--calcite-font-size-0: 1rem;
	--calcite-font-size-1: 1.125rem;
	--calcite-font-size-2: 1.25rem;
	--calcite-font-size-3: 1.625rem;
	--calcite-font-size-4: 2rem;
	--calcite-font-size-5: 2.5rem;
	--calcite-font-size-6: 3rem;
	--calcite-font-size-7: 3.5rem;
	--calcite-font-size-8: 4rem;
	--calcite-font-weight-light: 300;
	--calcite-font-weight-normal: 400;
	--calcite-font-weight-medium: 500;
	--calcite-font-weight-bold: 600;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	--calcite-border-radius: 4px;
	--calcite-ui-opacity-disabled: 0.5;
	--calcite-panel-width-multiplier: 1;
	--esri-calcite-theme-name: "light";
	-webkit-tap-highlight-color: transparent;
	--calcite-ui-brand-hover: #00619b;
	--calcite-ui-brand-press: #004874;
	--calcite-ui-background: #f8f8f8;
	--calcite-ui-foreground-1: #ffffff;
	--calcite-ui-foreground-2: #f3f3f3;
	--calcite-ui-foreground-3: #eaeaea;
	--calcite-ui-text-1: #151515;
	--calcite-ui-text-2: #4a4a4a;
	--calcite-ui-text-3: #6a6a6a;
	--calcite-ui-text-inverse: #ffffff;
	--calcite-ui-text-link: #00619b;
	--calcite-ui-border-1: #cacaca;
	--calcite-ui-border-2: #d4d4d4;
	--calcite-ui-border-3: #dfdfdf;
	--calcite-ui-border-input: #949494;
	--calcite-ui-info: #00619b;
	--calcite-ui-success: #35ac46;
	--calcite-ui-warning: #edd317;
	--calcite-ui-danger: #d83020;
	--calcite-ui-danger-hover: #a82b1e;
	--calcite-ui-danger-press: #7c1d13;
	--calcite-theme-name: "light";
	--calcite-ui-foreground-current: #c7eaff;
	--calcite-ui-inverse: #353535;
	--calcite-ui-inverse-hover: #2b2b2b;
	--calcite-ui-inverse-press: #202020;
	--calcite-alert-dismiss-progress-background: rgba(255, 255, 255, 0.8);
	--calcite-button-transparent-hover: rgba(0, 0, 0, 0.05);
	--calcite-button-transparent-press: rgba(0, 0, 0, 0.08);
	--calcite-link-blue-underline: rgba(0, 97, 155, 0.4);
	--calcite-scrim-background: rgba(255, 255, 255, 0.85);
	--calcite-ui-brand: #d42323;
	color: #323232;
	font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
	line-height: 16px;
	pointer-events: auto;
	box-sizing: inherit;
	font-weight: 300;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
	align-self: flex-start;
	flex: 1 0;
}

.attribution_esri {
	--lumo-base-color: #fff;
	--lumo-tint-5pct: hsla(0, 0%, 100%, 0.3);
	--lumo-tint-10pct: hsla(0, 0%, 100%, 0.37);
	--lumo-tint-20pct: hsla(0, 0%, 100%, 0.44);
	--lumo-tint-30pct: hsla(0, 0%, 100%, 0.5);
	--lumo-tint-40pct: hsla(0, 0%, 100%, 0.57);
	--lumo-tint-50pct: hsla(0, 0%, 100%, 0.64);
	--lumo-tint-60pct: hsla(0, 0%, 100%, 0.7);
	--lumo-tint-70pct: hsla(0, 0%, 100%, 0.77);
	--lumo-tint-80pct: hsla(0, 0%, 100%, 0.84);
	--lumo-tint-90pct: hsla(0, 0%, 100%, 0.9);
	--lumo-tint: #fff;
	--lumo-shade-5pct: hsla(214, 61%, 25%, 0.05);
	--lumo-shade-10pct: hsla(214, 57%, 24%, 0.1);
	--lumo-shade-20pct: hsla(214, 53%, 23%, 0.16);
	--lumo-shade-30pct: hsla(214, 50%, 22%, 0.26);
	--lumo-shade-40pct: hsla(214, 47%, 21%, 0.38);
	--lumo-shade-50pct: hsla(214, 45%, 20%, 0.5);
	--lumo-shade-60pct: hsla(214, 43%, 19%, 0.61);
	--lumo-shade-70pct: hsla(214, 42%, 18%, 0.72);
	--lumo-shade-80pct: hsla(214, 41%, 17%, 0.83);
	--lumo-shade-90pct: hsla(214, 40%, 16%, 0.94);
	--lumo-shade: hsl(214, 35%, 15%);
	--lumo-contrast-5pct: var(--lumo-shade-5pct);
	--lumo-contrast-10pct: var(--lumo-shade-10pct);
	--lumo-contrast-20pct: var(--lumo-shade-20pct);
	--lumo-contrast-30pct: var(--lumo-shade-30pct);
	--lumo-contrast-40pct: var(--lumo-shade-40pct);
	--lumo-contrast-50pct: var(--lumo-shade-50pct);
	--lumo-contrast-60pct: var(--lumo-shade-60pct);
	--lumo-contrast-70pct: var(--lumo-shade-70pct);
	--lumo-contrast-80pct: var(--lumo-shade-80pct);
	--lumo-contrast-90pct: var(--lumo-shade-90pct);
	--lumo-contrast: var(--lumo-shade);
	--lumo-header-text-color: var(--lumo-contrast);
	--lumo-body-text-color: var(--lumo-contrast-90pct);
	--lumo-secondary-text-color: var(--lumo-contrast-70pct);
	--lumo-tertiary-text-color: var(--lumo-contrast-50pct);
	--lumo-disabled-text-color: var(--lumo-contrast-30pct);
	--lumo-primary-color: hsl(214, 90%, 52%);
	--lumo-primary-color-50pct: hsla(214, 90%, 52%, 0.5);
	--lumo-primary-color-10pct: hsla(214, 90%, 52%, 0.1);
	--lumo-primary-text-color: var(--lumo-primary-color);
	--lumo-primary-contrast-color: #fff;
	--lumo-error-color: hsl(3, 100%, 61%);
	--lumo-error-color-50pct: hsla(3, 100%, 60%, 0.5);
	--lumo-error-color-10pct: hsla(3, 100%, 60%, 0.1);
	--lumo-error-text-color: hsl(3, 92%, 53%);
	--lumo-error-contrast-color: #fff;
	--lumo-success-color: hsl(145, 80%, 42%);
	--lumo-success-color-50pct: hsla(145, 76%, 44%, 0.55);
	--lumo-success-color-10pct: hsla(145, 76%, 44%, 0.12);
	--lumo-success-text-color: hsl(145, 100%, 32%);
	--lumo-success-contrast-color: #fff;
	--lumo-icons-align-center: "\ea01";
	--lumo-icons-align-left: "\ea02";
	--lumo-icons-align-right: "\ea03";
	--lumo-icons-angle-down: "\ea04";
	--lumo-icons-angle-left: "\ea05";
	--lumo-icons-angle-right: "\ea06";
	--lumo-icons-angle-up: "\ea07";
	--lumo-icons-arrow-down: "\ea08";
	--lumo-icons-arrow-left: "\ea09";
	--lumo-icons-arrow-right: "\ea0a";
	--lumo-icons-arrow-up: "\ea0b";
	--lumo-icons-bar-chart: "\ea0c";
	--lumo-icons-bell: "\ea0d";
	--lumo-icons-calendar: "\ea0e";
	--lumo-icons-checkmark: "\ea0f";
	--lumo-icons-chevron-down: "\ea10";
	--lumo-icons-chevron-left: "\ea11";
	--lumo-icons-chevron-right: "\ea12";
	--lumo-icons-chevron-up: "\ea13";
	--lumo-icons-clock: "\ea14";
	--lumo-icons-cog: "\ea15";
	--lumo-icons-cross: "\ea16";
	--lumo-icons-download: "\ea17";
	--lumo-icons-dropdown: "\ea18";
	--lumo-icons-edit: "\ea19";
	--lumo-icons-error: "\ea1a";
	--lumo-icons-eye: "\ea1b";
	--lumo-icons-eye-disabled: "\ea1c";
	--lumo-icons-menu: "\ea1d";
	--lumo-icons-minus: "\ea1e";
	--lumo-icons-ordered-list: "\ea1f";
	--lumo-icons-phone: "\ea20";
	--lumo-icons-photo: "\ea21";
	--lumo-icons-play: "\ea22";
	--lumo-icons-plus: "\ea23";
	--lumo-icons-redo: "\ea24";
	--lumo-icons-reload: "\ea25";
	--lumo-icons-search: "\ea26";
	--lumo-icons-undo: "\ea27";
	--lumo-icons-unordered-list: "\ea28";
	--lumo-icons-upload: "\ea29";
	--lumo-icons-user: "\ea2a";
	--lumo-size-xs: 1.625rem;
	--lumo-size-s: 1.875rem;
	--lumo-size-m: 2.25rem;
	--lumo-size-l: 2.75rem;
	--lumo-size-xl: 3.5rem;
	--lumo-icon-size-s: 1.25em;
	--lumo-icon-size-m: 1.5em;
	--lumo-icon-size-l: 2.25em;
	--lumo-icon-size: var(--lumo-icon-size-m);
	--lumo-space-xs: 0.25rem;
	--lumo-space-s: 0.5rem;
	--lumo-space-m: 1rem;
	--lumo-space-l: 1.5rem;
	--lumo-space-xl: 2.5rem;
	--lumo-space-wide-xs: calc(var(--lumo-space-xs) / 2) var(--lumo-space-xs);
	--lumo-space-wide-s: calc(var(--lumo-space-s) / 2) var(--lumo-space-s);
	--lumo-space-wide-m: calc(var(--lumo-space-m) / 2) var(--lumo-space-m);
	--lumo-space-wide-l: calc(var(--lumo-space-l) / 2) var(--lumo-space-l);
	--lumo-space-wide-xl: calc(var(--lumo-space-xl) / 2) var(--lumo-space-xl);
	--lumo-space-tall-xs: var(--lumo-space-xs) calc(var(--lumo-space-xs) / 2);
	--lumo-space-tall-s: var(--lumo-space-s) calc(var(--lumo-space-s) / 2);
	--lumo-space-tall-m: var(--lumo-space-m) calc(var(--lumo-space-m) / 2);
	--lumo-space-tall-l: var(--lumo-space-l) calc(var(--lumo-space-l) / 2);
	--lumo-space-tall-xl: var(--lumo-space-xl) calc(var(--lumo-space-xl) / 2);
	--lumo-border-radius-s: 0.25em;
	--lumo-border-radius-m: var(--lumo-border-radius, 0.25em);
	--lumo-border-radius-l: 0.5em;
	--lumo-border-radius: 0.25em;
	--lumo-box-shadow-xs: 0 1px 4px -1px var(--lumo-shade-50pct);
	--lumo-box-shadow-s: 0 2px 4px -1px var(--lumo-shade-20pct), 0 3px 12px -1px var(--lumo-shade-30pct);
	--lumo-box-shadow-m: 0 2px 6px -1px var(--lumo-shade-20pct), 0 8px 24px -4px var(--lumo-shade-40pct);
	--lumo-box-shadow-l: 0 3px 18px -2px var(--lumo-shade-20pct), 0 12px 48px -6px var(--lumo-shade-40pct);
	--lumo-box-shadow-xl: 0 4px 24px -3px var(--lumo-shade-20pct), 0 18px 64px -8px var(--lumo-shade-40pct);
	--lumo-clickable-cursor: default;
	--lumo-font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", Helvetica, Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	--lumo-font-size-xxs: 0.75rem;
	--lumo-font-size-xs: 0.8125rem;
	--lumo-font-size-s: 0.875rem;
	--lumo-font-size-m: 1rem;
	--lumo-font-size-l: 1.125rem;
	--lumo-font-size-xl: 1.375rem;
	--lumo-font-size-xxl: 1.75rem;
	--lumo-font-size-xxxl: 2.5rem;
	--lumo-line-height-xs: 1.25;
	--lumo-line-height-s: 1.375;
	--lumo-line-height-m: 1.625;
	--calcite-animation-timing: 300ms;
	--calcite-popper-transition: 150ms ease-in-out;
	--calcite-code-family: "Consolas", "Andale Mono", "Lucida Console", "Monaco", "monospace";
	--calcite-sans-family: "Avenir Next", "Avenir", "Helvetica Neue", "sans-serif";
	--calcite-font-size--3: 0.625rem;
	--calcite-font-size--2: 0.75rem;
	--calcite-font-size--1: 0.875rem;
	--calcite-font-size-0: 1rem;
	--calcite-font-size-1: 1.125rem;
	--calcite-font-size-2: 1.25rem;
	--calcite-font-size-3: 1.625rem;
	--calcite-font-size-4: 2rem;
	--calcite-font-size-5: 2.5rem;
	--calcite-font-size-6: 3rem;
	--calcite-font-size-7: 3.5rem;
	--calcite-font-size-8: 4rem;
	--calcite-font-weight-light: 300;
	--calcite-font-weight-normal: 400;
	--calcite-font-weight-medium: 500;
	--calcite-font-weight-bold: 600;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	--calcite-border-radius: 4px;
	--calcite-ui-opacity-disabled: 0.5;
	--calcite-panel-width-multiplier: 1;
	--esri-calcite-theme-name: "light";
	-webkit-tap-highlight-color: transparent;
	--calcite-ui-brand-hover: #00619b;
	--calcite-ui-brand-press: #004874;
	--calcite-ui-background: #f8f8f8;
	--calcite-ui-foreground-1: #ffffff;
	--calcite-ui-foreground-2: #f3f3f3;
	--calcite-ui-foreground-3: #eaeaea;
	--calcite-ui-text-1: #151515;
	--calcite-ui-text-2: #4a4a4a;
	--calcite-ui-text-3: #6a6a6a;
	--calcite-ui-text-inverse: #ffffff;
	--calcite-ui-text-link: #00619b;
	--calcite-ui-border-1: #cacaca;
	--calcite-ui-border-2: #d4d4d4;
	--calcite-ui-border-3: #dfdfdf;
	--calcite-ui-border-input: #949494;
	--calcite-ui-info: #00619b;
	--calcite-ui-success: #35ac46;
	--calcite-ui-warning: #edd317;
	--calcite-ui-danger: #d83020;
	--calcite-ui-danger-hover: #a82b1e;
	--calcite-ui-danger-press: #7c1d13;
	--calcite-theme-name: "light";
	--calcite-ui-foreground-current: #c7eaff;
	--calcite-ui-inverse: #353535;
	--calcite-ui-inverse-hover: #2b2b2b;
	--calcite-ui-inverse-press: #202020;
	--calcite-alert-dismiss-progress-background: rgba(255, 255, 255, 0.8);
	--calcite-button-transparent-hover: rgba(0, 0, 0, 0.05);
	--calcite-button-transparent-press: rgba(0, 0, 0, 0.08);
	--calcite-link-blue-underline: rgba(0, 97, 155, 0.4);
	--calcite-scrim-background: rgba(255, 255, 255, 0.85);
	--calcite-ui-brand: #d42323;
	color: #323232;
	font-family: "Avenir Next", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
	line-height: 16px;
	pointer-events: auto;
	box-sizing: inherit;
	font-weight: 400;
	padding: 0 5px;
	text-align: right;
	white-space: nowrap;
	align-self: flex-end;
}

.triangle_top_info {
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 7.5px 10px 7.5px;
	border-color: transparent transparent #ffffff transparent;
	position: absolute;
	top: -8px;
	right: 20px;
}

.tooltip {
	position: absolute;
	z-index: 5;
	pointer-events: none;
	background-color: rgba(255, 255, 255, 0.9);
	border: 1px solid #bbb;
	border-radius: 3px;
	padding: 2px 4px;
	white-space: nowrap;
	display: none;

	text-rendering: optimizeLegibility;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 450;
	font-size: 0.875rem;
	line-height: 1.43;
	letter-spacing: 0.01071em;
	color: black;
	margin-top: -4px;
	margin-left: 15px;
}
