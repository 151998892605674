@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";
html,
body,
#root {
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

.map {
	padding: 0;
	margin: 0;
	height: calc(100vh - 90px);
	width: 100%;
	position: relative;
}
.esri-view .esri-view-surface--inset-outline:focus::after {
	outline: none !important;
}

.esri-basemap-gallery__item:first-child {
	margin-top: 0 !important;
}
.esri-basemap-gallery__item-title {
	font-size: 16px !important;
}

.esri-basemap-gallery {
	width: 230px !important;
}
